/* eslint-disable jsx-a11y/img-redundant-alt,react-hooks/exhaustive-deps */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import useTheme from '@mui/styles/useTheme';
import fastDeepEqual from 'fast-deep-equal';
import { applySpec } from 'ramda';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import { useSelector, createSelector } from '@wpa/redux-utils';

import { AnimationConfig, MarkerEvent, Message, SendMessageFunc, State, IPostMessage } from '../../types';
import { isCompareDate, isoToLocaleDate } from '../../utils';
import { OptionsContainer } from './styled';
import { Option } from './Option/Option';
import { Avatar } from './Avatar';
import { MessagesSendDay } from './Date/Date';
import { FakeButton } from './FakeButton/FakeButton';
import { Bubble } from './Bubble/Bubble';
import { getAnimationSpeedOptions, getClassForAnimation } from './helpers';
import { StartsSequence } from './StartsSequence';

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    timestamp: {
      display: 'flex',
      justifyContent: 'center',
      color: '#999',
      fontWeight: 600,
      margin: '10px 0px',
      textTransform: 'uppercase',
    },
    container: {
      width: '100%',
      display: 'flex',
      flex: '0 0 auto',
      justifyContent: 'flex-start',
    },
    mineContainer: { justifyContent: 'flex-end' },
    containerContent: {
      maxWidth: '500px',
      marginLeft: '5px',
      marginRight: '48px',
    },
    mineContainerContent: {
      marginLeft: '58px',
      marginRight: '7px',
    },
    containerContentEnd: { marginBottom: '10px' },
    bubble: {
      margin: '3px 0px',
      padding: '7px 10px 7px 10px',
      display: 'flex',
      flexDirection: 'column',
      color: theme.custom.messageColorBot,
      background: theme.custom.messageBackgroundBot,
      borderRadius: '2px 10px 10px 2px',
      border: `1px solid ${theme.custom.messageBorderColorBot}`,
      boxShadow: '3px 3px 5px rgba(0,0,0,0.4)',
    },
    bubbleMessage: {
      cursor: 'default',
      wordBreak: 'break-word',
      '& a': {
        color: theme.custom.linkColor,
        textDecoration: 'none',
      },
      '& a:visited': {
        color: theme.custom.linkColor,
      },
      '& a:active': {
        color: theme.custom.linkColor,
      },
      '& a:hover': {
        color: theme.custom.linkColor,
        textDecoration: 'underline',
      },
      '& img': {
        cursor: 'pointer',
        borderRadius: '5px',
        width: '100%',
        objectFit: 'contain',
      },
      '& iframe': {
        borderRadius: '5px',
      },
    },
    bubbleStart: { borderTopLeftRadius: '10px', marginTop: 0 },
    bubbleEnd: { borderBottomLeftRadius: '10px' },
    bubbleMine: {
      background: theme.custom.messageBackground,
      color: theme.custom.messageColor,
      borderRadius: '10px 2px 2px 10px',
      border: `1px solid ${theme.custom.messageBorderColor}`,
    },
    bubbleMineStart: { borderTopRightRadius: '10px' },
    bubbleMineEnd: { borderBottomRightRadius: '10px' },
    info: {
      paddingLeft: '9px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      color: theme.custom.botNameTimeColor,
    },
    mineInfo: {
      paddingRight: '11px',
      justifyContent: 'flex-end',
      textAlign: 'right',
      color: theme.custom.userTimeColor,
    },
    infoWithIcon: { paddingLeft: '45px' },
    mineInfoWithIcon: { paddingRight: '49px' },
    withUserIcon: { marginRight: '50px' },
    withBotIcon: { marginLeft: '45px' },
    firsMessage: { marginTop: '15px' },
    fakeButton: { position: 'absolute', left: '-1000px', height: 0 },
  }),
  { name: 'Message' },
);

type ClickedButton = {
  idx: number;
  text: string;
};

type MessageSelected = {
  windowCurrentWidth: State['windowCurrentWidth'];
  currentSize: State['currentSize'];
  animationEnabled: State['animationEnabled'];
  animationSpeed: State['animationSpeed'];
  withBotIcon: State['withBotIcon'];
  withUserIcon: State['withUserIcon'];
  showSequence: State['showSequence'];
  extendedMode: State['extendedMode'];
  isVisibleCopyButton: boolean;
};

type MessageProps = {
  message: Message;
  prevMessage: Message;
  botName: string;
  isFirsMessage: boolean;
  isLastMessage: boolean;
  isFullScreen: boolean;
  sendMessage: SendMessageFunc;
  sendToParent: (data: IPostMessage<MarkerEvent>) => void;
  onClick?: () => unknown;
};

const messageSelector = applySpec({
  windowCurrentWidth: createSelector(['app', 'windowCurrentWidth']),
  currentSize: createSelector(['app', 'currentSize']),
  animationEnabled: createSelector(['app', 'animationEnabled']),
  animationSpeed: createSelector(['app', 'animationSpeed']),
  withBotIcon: createSelector(['app', 'withBotIcon']),
  withUserIcon: createSelector(['app', 'withUserIcon']),
  showSequence: createSelector(['app', 'showSequence']),
  extendedMode: createSelector(['app', 'extendedMode']),
  isVisibleCopyButton: createSelector(['app', 'isVisibleCopyButton']),
});

const DISTANCE_FROM_LAST_BOT_MSG = 37; // from bot message end to user message start
const RIGHT_MARGIN = 20; // from right chat land to user message

// eslint-disable-next-line sonarjs/cognitive-complexity
const InnerMessage = React.forwardRef<HTMLDivElement, MessageProps>((props, ref) => {
  const {
    message,
    prevMessage,
    botName,
    isFirsMessage,
    isLastMessage,
    isFullScreen,
    sendMessage,
    sendToParent,
    onClick,
  } = props;
  const { data, isMine, startsSequence, endsSequence } = message;
  const { id, time, responseActions = [], replyActionIndex, disabled } = data;

  const {
    windowCurrentWidth,
    currentSize,
    animationEnabled,
    withBotIcon,
    withUserIcon,
    showSequence,
    animationSpeed,
    extendedMode,
    isVisibleCopyButton,
  } = useSelector<State, MessageSelected>(messageSelector, fastDeepEqual);
  const classes = useStyles();
  const theme = useTheme<Theme>();

  const getMessageDate = React.useCallback((t) => isoToLocaleDate(t, true), []);
  const currentMessageDate = getMessageDate(time);
  const isNeedShowMessagesSendDay = !isCompareDate(
    getMessageDate(prevMessage ? prevMessage.data.time : new Date()),
    getMessageDate(time),
  );

  const avatarUrl = isMine ? theme.customImages.messageIconUrl : theme.customImages.messageIconUrlBot;

  const showUserIcon = withUserIcon && isMine && startsSequence;
  const showBotIcon = withBotIcon && !isMine && startsSequence;

  const isRenderOptionsContainer = animationEnabled
    ? !!responseActions.length && isLastMessage && !Number.isFinite(replyActionIndex)
    : !!responseActions.length;

  const optionsContainerRef = React.useRef<HTMLDivElement>(null);
  const [clickedButton, setClickedButton] = React.useState<ClickedButton>({
    idx: -1,
    text: '',
  });
  // @ts-ignore TODO fix
  const [animationConfig, setAnimationConfig] = React.useState<AnimationConfig>({
    displacementX: 0,
    displacementY: 0,
    fakeBtnWidth: 0,
    buttonPositionX: 0,
    buttonPositionY: 0,
    leftIndent: 0,
    clickedBtnWidth: 0,
    ...getAnimationSpeedOptions(animationSpeed),
  });

  const calculateDisplacement = () => {
    const { buttonPositionX, buttonPositionY, leftIndent, fakeBtnWidth } = animationConfig;
    const chatWidth = isFullScreen ? windowCurrentWidth : currentSize.width;

    return {
      displacementX: chatWidth - (leftIndent + buttonPositionX + fakeBtnWidth + RIGHT_MARGIN),
      displacementY: DISTANCE_FROM_LAST_BOT_MSG - buttonPositionY,
    };
  };

  React.useEffect(() => {
    setAnimationConfig((prev) => ({ ...prev, ...calculateDisplacement() }));
  }, [animationConfig.fakeBtnWidth]);

  return (
    <>
      <div
        ref={ref}
        role="presentation"
        onClick={() => onClick?.()}
        className={clsx(classes.root, { [classes.firsMessage]: isFirsMessage })}
      >
        {isNeedShowMessagesSendDay && <MessagesSendDay date={currentMessageDate} />}
        {showSequence && (
          <StartsSequence
            classes={classes}
            time={time}
            isMine={isMine}
            startsSequence={startsSequence}
            botName={botName}
            withUserIcon={withUserIcon}
            withBotIcon={withBotIcon}
          />
        )}
        <div className={clsx(classes.container, { [classes.mineContainer]: isMine })}>
          {showBotIcon && <Avatar src={avatarUrl} isMine={isMine} />}
          <div
            className={clsx(classes.containerContent, {
              [classes.mineContainerContent]: isMine,
              [classes.containerContentEnd]: endsSequence,
              [classes.withBotIcon]: withBotIcon && !startsSequence,
              [classes.withUserIcon]: withUserIcon && !startsSequence,
            })}
          >
            <Bubble
              classes={classes}
              message={message}
              sendMessage={sendMessage}
              isFullScreen={isFullScreen}
              extendedMode={extendedMode}
              sendToParent={sendToParent}
              isVisibleCopyButton={isVisibleCopyButton}
            />
            {isRenderOptionsContainer && (
              <OptionsContainer id="answer-options" animationconfig={animationConfig} ref={optionsContainerRef}>
                {(responseActions || []).map((action, index) => (
                  <Option
                    key={`${index + 1}_option`}
                    className={getClassForAnimation(clickedButton.idx, index)}
                    id={id}
                    action={action}
                    actionIndex={index}
                    replyActionIndex={replyActionIndex}
                    disabled={disabled}
                    sendMessage={sendMessage}
                    setClickedButton={setClickedButton}
                    optionsContainerRef={optionsContainerRef}
                    animationEnabled={animationEnabled}
                    setAnimationConfig={setAnimationConfig}
                    sendMessageDelay={animationConfig.sendMessageDelay}
                  />
                ))}
              </OptionsContainer>
            )}
          </div>
          {showUserIcon && <Avatar src={avatarUrl} isMine={isMine} />}
        </div>
      </div>
      {clickedButton.text && (
        <FakeButton classes={classes} text={clickedButton.text} setAnimationConfig={setAnimationConfig} />
      )}
    </>
  );
});

export const MessageComponent = React.memo(InnerMessage);
