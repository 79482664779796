import React, { useCallback, useRef, memo, useContext } from 'react';
import { applySpec } from 'ramda';
import fastDeepEqual from 'fast-deep-equal';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Typography from '@mui/material/Typography';
import { useSelector, createSelector } from '@wpa/redux-utils';

import { Box, Button } from '@mui/material';
import type { State } from '../../types';
import { throttle } from '../../utils';
import { setEnabled, toggleFullScreen, setCurrentPosition } from '../../store/rootSlice';
import { HeaderWrapper, Draggable, SubHeaderWrapper, SubNavFullscreenIcon, SubNavExitIcon } from './styled';
import { TypographyContext } from '../../theme/ThemeProvider';

const headerSelector = applySpec({
  isMobile: createSelector(['app', 'isMobile']),
  isFullScreen: createSelector(['app', 'isFullScreen']),
  windowCurrentWidth: createSelector(['app', 'windowCurrentWidth']),
  windowCurrentHeight: createSelector(['app', 'windowCurrentHeight']),
  currentSize: createSelector(['app', 'currentSize']),
  currentPosition: createSelector(['app', 'currentPosition']),
  disableDraggable: createSelector(['app', 'disableDraggable']),
  disableHeaderNav: createSelector(['app', 'disableHeaderNav']),
  showTextSizeControls: createSelector(['app', 'showTextSizeControls']),
});

type HeaderSelected = {
  isMobile: State['isMobile'];
  isFullScreen: State['isFullScreen'];
  windowCurrentWidth: State['windowCurrentWidth'];
  windowCurrentHeight: State['windowCurrentHeight'];
  currentSize: State['currentSize'];
  currentPosition: State['currentPosition'];
  disableDraggable: State['disableDraggable'];
  disableHeaderNav: State['disableHeaderNav'];
  showTextSizeControls: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Header = memo(({ children }) => {
  const {
    isMobile,
    isFullScreen,
    windowCurrentWidth,
    windowCurrentHeight,
    currentSize,
    currentPosition,
    disableDraggable,
    disableHeaderNav,
    showTextSizeControls,
  } = useSelector<State, HeaderSelected>(headerSelector, fastDeepEqual);
  const { setTypography, typography } = useContext(TypographyContext);

  const divRef = useRef<HTMLDivElement>(null);
  const startMousePosition = useRef({ x: 0, y: 0 });

  const handleDrag = useCallback(
    (e) => {
      const distanceY = startMousePosition.current.y - e.pageY;
      const distanceX = startMousePosition.current.x - e.pageX;

      let newPositionY = currentPosition.bottom + distanceY > 0 ? currentPosition.bottom + distanceY : 0;
      let newPositionX = currentPosition.right + distanceX > 0 ? currentPosition.right + distanceX : 0;

      if (windowCurrentHeight - (newPositionY + currentSize.height) < 0) {
        newPositionY = windowCurrentHeight - currentSize.height;
      }
      if (windowCurrentWidth - (newPositionX + currentSize.width) < 0) {
        newPositionX = windowCurrentWidth - currentSize.width;
      }

      setCurrentPosition({ bottom: newPositionY, right: newPositionX });
    },
    [
      currentPosition.bottom,
      currentPosition.right,
      currentSize.height,
      currentSize.width,
      windowCurrentHeight,
      windowCurrentWidth,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const drug = useCallback(
    throttle((e) => handleDrag(e), 40),
    [handleDrag],
  );

  document.addEventListener('mouseup', () => {
    document.removeEventListener('mousemove', drug);
  });

  const drugStart = useCallback(
    (e) => {
      if (!disableDraggable && !isFullScreen) {
        document.addEventListener('mousemove', drug);
        startMousePosition.current = { x: e.pageX, y: e.pageY };
      }
    },
    [disableDraggable, drug, isFullScreen],
  );

  const handleFullScreen = useCallback(() => {
    toggleFullScreen();
  }, []);

  const handleMinimize = useCallback(() => {
    setEnabled(false);
  }, []);

  const cursorActive = !isFullScreen && !disableDraggable ? 'grabbing' : 'initial';
  const cursorHover = isFullScreen || disableDraggable ? 'initial' : 'move';

  if (disableHeaderNav) {
    return (
      <HeaderWrapper>
        <SubHeaderWrapper>
          <Typography sx={{ textAlign: 'center' }}>{children}</Typography>
        </SubHeaderWrapper>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <Draggable
        ref={divRef}
        onDoubleClick={handleFullScreen}
        onMouseDown={drugStart}
        // @ts-ignore
        cursorhover={cursorHover}
        cursoractive={cursorActive}
      />
      <SubHeaderWrapper>
        {!isMobile ? (
          <SubNavFullscreenIcon onClick={handleFullScreen}>
            {isFullScreen ? (
              <FullscreenIcon sx={{ width: 24, height: 24 }} />
            ) : (
              <FullscreenExitIcon sx={{ width: 24, height: 24 }} />
            )}
          </SubNavFullscreenIcon>
        ) : null}
        <SubNavExitIcon onClick={handleMinimize}>
          <MinimizeIcon sx={{ width: 24, height: 24 }} />
        </SubNavExitIcon>
        {showTextSizeControls && (
          <Box style={{ position: 'absolute', left: 0, zIndex: 10100 }}>
            <Button
              variant="contained"
              color={typography.fontSize === 14 ? 'primary' : 'inherit'}
              style={{ marginRight: 10, marginLeft: 10, fontSize: 14 }}
              onClick={() => {
                setTypography({ ...typography, fontSize: 14 });
              }}
            >
              عادي
            </Button>
            <Button
              variant="contained"
              color={typography.fontSize === 24 ? 'primary' : 'inherit'}
              style={{ fontSize: 14 }}
              onClick={() => {
                setTypography({ ...typography, fontSize: 24 });
              }}
            >
              كبير
            </Button>
          </Box>
        )}
        {showTextSizeControls && (isMobile || isFullScreen) ? null : (
          <Typography sx={{ textAlign: 'center' }}>{showTextSizeControls ? 'النظام' : children}</Typography>
        )}
      </SubHeaderWrapper>
    </HeaderWrapper>
  );
});
