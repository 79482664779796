/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import BaseSkeleton from '@mui/material/Skeleton';
import { format } from 'date-fns';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
// import Avatar from '@mui/material/Avatar';
import SuccessIcon from '@mui/icons-material/CheckCircleRounded';
import FailIcon from '@mui/icons-material/CancelRounded';

import { IPostMessage, MarkerEvent, Message, SendMessageFunc } from '../../../types';
import { parseHtml } from '../../Body/BodyHelper';
import { printMessage2 } from '../helpers';
import { Countries } from '../Countries/Countries';
import { Image } from '../Image';
import { AccuracyQnAMarker } from '../../../enums/marker.enum';
import { POST_MESSAGE_EVENT_TYPE } from '../../../constants';

function Skeleton({ imgHeight }) {
  return <BaseSkeleton variant="rectangular" width="100%" height={imgHeight} />;
}

type BubbleProps = {
  classes: Record<string, string>;
  sendMessage: SendMessageFunc;
  isFullScreen: boolean;
  message: Message;
  extendedMode: boolean;
  sendToParent: (data: IPostMessage<MarkerEvent>) => void;
  isVisibleCopyButton: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function Bubble(props: BubbleProps): React.ReactElement {
  const { classes, sendMessage, isFullScreen, message, extendedMode, sendToParent, isVisibleCopyButton } = props;
  const { data, isMine, startsSequence, endsSequence, marker, hideMarkingButtons } = message;
  const { id, time, image, topImage, responseText, incomingEventId } = data;

  const [show, setShow] = React.useState(false);
  const [imgHeight, setImgHeight] = React.useState(468);
  const forwardRef = React.useRef<HTMLDivElement>(null);
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);

  React.useLayoutEffect(() => {
    let sandboxImg;

    if (image) {
      new Promise((resolve, reject) => {
        sandboxImg = new window.Image();
        sandboxImg.crossOrigin = undefined;
        sandboxImg.onload = (e) => resolve(e);
        sandboxImg.onerror = (e) => reject(e);
        sandboxImg.src = image;
      })
        .then(() => {
          setShow(true);
        })
        .catch(console.error);
    }

    return () => {
      if (sandboxImg) {
        sandboxImg.onload = undefined;
        sandboxImg.onerror = undefined;
        sandboxImg = undefined;
      }
    };
  }, [image]);

  React.useEffect(() => {
    const rowWidth = forwardRef.current?.clientWidth || 468;
    const size = message.data.imageSize;
    const coefficient = size && size.height && size.width ? size.height / size.width : 0;
    setImgHeight(rowWidth * coefficient);
  }, [forwardRef.current?.clientWidth, message.data.imageSize]);

  const textRef = useRef();
  const friendlyTimestamp = format(new Date(time), 'EEEE, MMMM do, yyyy hh:mm a');
  const component = printMessage2(
    data,
    isFullScreen,
    isVisibleCopyButton && !isMine,
    isVisibleTooltip,
    setVisibleTooltip,
    textRef,
  );
  const countries = responseText && responseText.includes('itemslist') && parseHtml(responseText);

  const handleCorrectAnswer = React.useCallback(() => {
    sendToParent({
      type: POST_MESSAGE_EVENT_TYPE,
      payload: { marker: AccuracyQnAMarker.Correct, messageId: id, incomingEventId },
    });
  }, [id, incomingEventId, sendToParent]);

  const handleIncorrectAnswer = React.useCallback(() => {
    sendToParent({
      type: POST_MESSAGE_EVENT_TYPE,
      payload: { marker: AccuracyQnAMarker.Incorrect, messageId: id, incomingEventId },
    });
  }, [id, incomingEventId, sendToParent]);

  const showProcessedLabel =
    (marker === AccuracyQnAMarker.Correct || marker === AccuracyQnAMarker.Incorrect) &&
    extendedMode &&
    !hideMarkingButtons;
  const showMarkingButtons = !!incomingEventId && extendedMode && !showProcessedLabel && !hideMarkingButtons;

  return (
    <Box
      className={clsx(
        classes.bubble,
        { [classes.bubbleStart]: startsSequence },
        { [classes.bubbleEnd]: endsSequence },
        { [classes.bubbleMine]: isMine },
        { [classes.bubbleMineStart]: isMine && startsSequence },
        { [classes.bubbleMineEnd]: isMine && endsSequence },
      )}
      title={friendlyTimestamp}
      sx={{ position: 'relative', opacity: message.data.isThought ? '0.7' : '1' }}
    >
      <Box ref={forwardRef} className={classes.bubbleMessage}>
        {/* eslint-disable-next-line no-extra-boolean-cast */}
        {!!countries ? (
          <Countries countries={countries} sendMessage={sendMessage} message={data} />
        ) : !!image && topImage ? (
          <>
            {show ? (
              <Image src={image} alt="image" /* style={{ height: imgHeight }} */ />
            ) : (
              <Skeleton imgHeight={imgHeight} />
            )}
            {component}
          </>
        ) : !!image && !topImage ? (
          <>
            {component}
            {show ? (
              <Image src={image} alt="image" /* style={{ height: imgHeight }} */ />
            ) : (
              <Skeleton imgHeight={imgHeight} />
            )}
          </>
        ) : (
          component
        )}
        <Stack spacing={1} direction="row" justifyContent="flex-end" sx={{ pt: '5px' }}>
          {showProcessedLabel && (
            <Chip label={`Processed as: ${marker.toLowerCase()}`} variant="filled" color="info" size="small" />
          )}
          {showMarkingButtons && (
            <>
              <Chip
                label="Correct answer"
                variant="filled"
                color="success"
                size="small"
                avatar={<SuccessIcon />}
                onClick={handleCorrectAnswer}
              />
              <Chip
                label="Incorrect answer"
                variant="filled"
                color="error"
                size="small"
                avatar={<FailIcon />}
                onClick={handleIncorrectAnswer}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
