import React from 'react';
import fastDeepEqual from 'fast-deep-equal';
import { applySpec } from 'ramda';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import { useSelector, createSelector } from '@wpa/redux-utils';
// import { ResizeObserver } from '@juggle/resize-observer';

import { SendMessageFunc } from '../../types';
import { debounceRamda } from '../../utils';
import { BaseButton } from '../BaseButton';
import { MessageInput } from '../MessageInput';
import { FooterWrapper, ButtonsWrapper } from './styled';

type FooterProps = {
  sendMessage: SendMessageFunc;
  isCopyrightVisible?: boolean;
  autoFocus?: boolean;
};

export const Footer = React.memo((props: FooterProps) => {
  const { sendMessage, isCopyrightVisible = true, autoFocus } = props;
  const {
    localizedTexts: { inputPlaceholder },
    footerButtons,
    showTextSizeControls,
  } = useSelector(
    applySpec({
      localizedTexts: createSelector(['app', 'localizedTexts']),
      footerButtons: createSelector(['app', 'footerButtons']),
      showTextSizeControls: createSelector(['app', 'showTextSizeControls']),
    }),
    fastDeepEqual,
  );
  // const footerWrapperRef = React.useRef(null);

  const optionClickHandler = debounceRamda(100, (event) => {
    event.preventDefault();
    const dataAttrChek = event.target.getAttribute('data-value');

    const dataAttr = dataAttrChek || event.target.parentNode.getAttribute('data-value');

    if (dataAttr) {
      sendMessage({ value: dataAttr, type: 'button' });
    }
  });

  // React.useLayoutEffect(() => {
  //   const ro = new ResizeObserver((entries) => {
  //     setFooterHeight(entries[0].borderBoxSize[0].blockSize);
  //   });
  //   ro.observe(footerWrapperRef.current);
  //
  //   return () => {
  //     ro.disconnect();
  //   };
  // }, [setFooterHeight]);

  return (
    <FooterWrapper
    // ref={footerWrapperRef}
    >
      <MessageInput
        autoFocus={autoFocus}
        placeholder={showTextSizeControls ? ' اكتب سؤالك' : inputPlaceholder}
        onSend={(content) => sendMessage({ value: content, type: 'text' })}
      />
      {footerButtons && !!footerButtons.length && (
        <ButtonsWrapper>
          {footerButtons.map(({ label, command }) => (
            <BaseButton key={label} fullWidth data-value={command} onClick={optionClickHandler}>
              {label}
            </BaseButton>
          ))}
        </ButtonsWrapper>
      )}

      <Box mt={1} sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {isCopyrightVisible && (
          <Link href="https://www.metadialog.com" target="_blank" underline="none" variant="caption" color="#84aed7">
            Powered by MetaDialog
          </Link>
        )}
      </Box>
    </FooterWrapper>
  );
});
